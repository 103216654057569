var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showDeletePublishRequest)?_c('ModalDeletePublishRequest',{attrs:{"showModal":_vm.showDeletePublishRequest,"request":_vm.requestToDelete,"onClickCancel":_vm.hideModal},on:{"requestDeleted":_vm.deleteRequest}}):_vm._e(),_c('p',{staticClass:"title"},[_vm._v("Create stories requests")]),_c('table',{staticClass:"table is-fullwidth"},[_vm._m(0),_c('tbody',_vm._l((_vm.requests),function(request,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(request.ProfileName))]),_c('td',[_vm._v(_vm._s(request.ProfileEmail))]),_c('td',[_vm._v(_vm._s(request.Motivation))]),_c('td',[_vm._v(_vm._s(request.CreatedOn))]),_c('td',[_c('span',{staticClass:"tag",class:{
              'is-danger': request.Status === -1,
              'is-success': request.Status === 1,
            }},[_vm._v(_vm._s(_vm.getStatusLabel(request.Status)))])]),_c('td',[_c('div',{staticClass:"field is-grouped"},[_c('p',{staticClass:"control"},[_c('a',{staticClass:"level-item is-size-3 mr-3",class:{
                  'has-text-danger': request.Status === -1,
                  'has-text-grey-light': request.Status !== -1,
                },attrs:{"title":"Deny request"},on:{"click":function($event){return _vm.declineRequest(request)}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'ban']}})],1)])]),_c('p',{staticClass:"control level-item is-size-3",class:{ 'has-text-grey-light': request.Status !== 0 }},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'question']}})],1)]),_c('p',{staticClass:"control"},[_c('a',{staticClass:"level-item is-size-3 ml-3",class:{
                  'has-text-success': request.Status === 1,
                  'has-text-grey-light': request.Status !== 1,
                },attrs:{"title":"Accept request"},on:{"click":function($event){return _vm.acceptRequest(request)}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'check']}})],1)])])])]),_c('td',{staticClass:"has-text-right"},[_c('p',{staticClass:"control"},[_c('a',{staticClass:"level-item",on:{"click":function($event){return _vm.setShowDeletePublishRequest(request)}}},[_c('span',{staticClass:"icon has-text-danger"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash-alt']}})],1)])])])])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Profile")]),_c('th',[_vm._v("Email")]),_c('th',{attrs:{"width":"300px"}},[_vm._v("Motivation")]),_c('th',[_vm._v("Requested on")]),_c('th',[_vm._v("Status")]),_c('th',{attrs:{"width":"150px"}},[_vm._v(" ")]),_c('th',{attrs:{"width":"75px"}},[_vm._v(" ")])])])
}]

export { render, staticRenderFns }