<template>
  <div>
    <ModalDeletePublishRequest
      v-if="showDeletePublishRequest"
      :showModal="showDeletePublishRequest"
      :request="requestToDelete"
      :onClickCancel="hideModal"
      @requestDeleted="deleteRequest"
    />

    <p class="title">Create stories requests</p>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Profile</th>
          <th>Email</th>
          <th width="300px">Motivation</th>
          <th>Requested on</th>
          <th>Status</th>
          <th width="150px">&nbsp;</th>
          <th width="75px">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(request, index) in requests" :key="index">
          <td>{{ request.ProfileName }}</td>
          <td>{{ request.ProfileEmail }}</td>
          <td>{{ request.Motivation }}</td>
          <td>{{ request.CreatedOn }}</td>
          <td>
            <span
              class="tag"
              :class="{
                'is-danger': request.Status === -1,
                'is-success': request.Status === 1,
              }"
              >{{ getStatusLabel(request.Status) }}</span
            >
          </td>

          <td>
            <div class="field is-grouped">
              <p class="control">
                <a
                  @click="declineRequest(request)"
                  class="level-item is-size-3 mr-3"
                  :class="{
                    'has-text-danger': request.Status === -1,
                    'has-text-grey-light': request.Status !== -1,
                  }"
                  title="Deny request"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'ban']" />
                  </span>
                </a>
              </p>
              <p
                class="control level-item is-size-3"
                :class="{ 'has-text-grey-light': request.Status !== 0 }"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'question']" />
                </span>
              </p>
              <p class="control">
                <a
                  @click="acceptRequest(request)"
                  class="level-item is-size-3 ml-3"
                  :class="{
                    'has-text-success': request.Status === 1,
                    'has-text-grey-light': request.Status !== 1,
                  }"
                  title="Accept request"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'check']" />
                  </span>
                </a>
              </p>
            </div>
          </td>
          <td class="has-text-right">
            <p class="control">
              <a
                @click="setShowDeletePublishRequest(request)"
                class="level-item"
              >
                <span class="icon has-text-danger">
                  <font-awesome-icon :icon="['fas', 'trash-alt']" />
                </span>
              </a>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import channelProvider from '@/providers/channel'

export default {
  components: {
    ModalDeletePublishRequest: () =>
      import('@/components/Article/ModalDeletePublishRequest'),
  },

  props: {},

  data() {
    return {
      isLoading: false,
      requests: [],
      showDeletePublishRequest: false,
      requestToDelete: null,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  created() {
    this.getChannelArticlePublishRequests()
  },

  methods: {
    getChannelArticlePublishRequests() {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      channelProvider.methods
        .getChannelArticlePublishRequests(this.channel.Id)
        .then((response) => {
          if (response.status === 200) {
            this.requests = response.data
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false
        })
    },

    getStatusLabel(status) {
      let name = ''

      switch (status) {
        case 0:
          name = 'Pending'
          break
        case 1:
          name = 'Accepted'
          break
        case -1:
          name = 'Declined'
          break
      }
      return name
    },

    acceptRequest(request) {
      channelProvider.methods
        .acceptChannelArticlePublishRequest(this.channel.Id, request.Id)
        .then((response) => {
          if (response.status === 200) {
            this.updateRequest(response.data)
          }
        })
    },

    declineRequest(request) {
      channelProvider.methods
        .declineChannelArticlePublishRequest(this.channel.Id, request.Id)
        .then((response) => {
          if (response.status === 200) {
            this.updateRequest(response.data)
          }
        })
    },

    updateRequest(request) {
      let index = this.requests.findIndex((r) => r.Id === request.Id)
      if (index > -1) {
        Vue.set(this.requests, index, request)
      }
    },

    setShowDeletePublishRequest(request) {
      this.showDeletePublishRequest = true
      this.requestToDelete = request
    },

    deleteRequest(request) {
      let index = this.requests.findIndex((r) => r.Id === request.Id)
      if (index > -1) {
        this.requests.splice(index, 1)
      }
    },

    hideModal() {
      this.showDeletePublishRequest = false
      this.requestToDelete = null
    },
  },
}
</script>

<style></style>
